import { BusinessPageTypes } from "@/store/enums/business/BusinessPageTypes";
import { BankPageTypes } from "@/store/enums/bank/BankPageTypes";
import { Constants } from "@/core/config/constants";

export class DashboardDataContainerBase {
    totalScore: ContainerDataModel;
    containerData: ContainerEntity[]

    constructor(obj: any) {
        this.totalScore = new ContainerDataModel(obj.overall);
        this.containerData = this.getContainerData(obj.container_data);
    }

    getContainerData(containerDt: any): ContainerEntity[] {
        if (!containerDt) {
            return [];
        }

        return containerDt.map(ctd => new ContainerEntity(ctd));
    }

    sort(sorting) {
        switch (sorting) {
            case 'highest_score':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en2.item.stats.score - en1.item.stats.score);
                break;
            case 'lowest_score':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en1.item.stats.score - en2.item.stats.score);
                break;
            case 'highest_importance_score':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en2.item.stats.importance - en1.item.stats.importance);
                break;
            case 'lowest_importance_score':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en1.item.stats.importance - en2.item.stats.importance);
                break;
            case 'highest_negative_gap':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en1.item.stats.gap - en2.item.stats.gap);
                break;
            case 'highest_positive_gap':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en2.item.stats.gap - en1.item.stats.gap);
                break;
            case 'highest_standard_deviation':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en1.item.stats.score - en2.item.stats.score);
                break;
            case 'lowest_standard_deviation':
                this.containerData = this.containerData.sort((en1: ContainerEntity, en2: ContainerEntity) => en1.item.stats.score - en2.item.stats.score);
                break;
        }
    }
}

class ContainerDataModel {
    count: number;
    score: number;
    importance: number;
    gap: number;
    scores: number;
    respondents: number;
    name?: string;
    iconPath?: string;
    redirectUrl?: string;
    customersContainer?: ContainerEntityGroupItemCustomerContainer;

    constructor(obj: any) {
        this.count = obj.count;
        this.score = obj.score ? parseFloat(obj.score) : 0;
        this.importance = obj.importance ? parseFloat(obj.importance) : 0;
        this.gap = obj.gap ? parseFloat(obj.gap) : 0;
        this.scores = obj.scores ? parseFloat(obj.scores) : 0;
        this.respondents = obj.respondents ? parseFloat(obj.respondents) : 0;
        this.name = obj.name;
        this.iconPath = obj.icon_path;
        this.redirectUrl = obj.redirect_url;
        this.customersContainer = new ContainerEntityGroupItemCustomerContainer(obj.customers);
    }
}

class ContainerEntity {
    item: {
        id?: number,
        iconPath?: string,
        name: string,
        stats: ContainerDataModel
    }
    groups: ContainerEntityGroupItem[];

    constructor(obj) {
        this.item = obj.item;
        if (obj.item && obj.item.icon_path) {
            this.item.iconPath = obj.item.icon_path;
        }
        this.item.stats = new ContainerDataModel(obj.item.stats);
        this.groups = this.getGroupData(obj.groups);
    }

    getGroupData(groupData: any) {
        if(!groupData) {
            return [];
        }

        return groupData.map(gpd => new ContainerEntityGroupItem(gpd));
    }
}

class ContainerEntityGroupItemCustomerContainer {
    customers = [] as ContainerEntityGroupItemCustomer[];

    constructor(obj: any) {
        if (obj && obj.length > 0) {
            this.customers = obj.map(cs => new ContainerEntityGroupItemCustomer(cs));
            this.sort('lowest_score');
        }
    }

    sort(sorting) {
        switch (sorting) {
            case 'highest_score':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en2.stats.score - en1.stats.score);
                break;
            case 'lowest_score':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en1.stats.score - en2.stats.score);
                break;
            case 'highest_importance_score':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en2.stats.importance - en1.stats.importance);
                break;
            case 'lowest_importance_score':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en1.stats.importance - en2.stats.importance);
                break;
            case 'highest_negative_gap':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en1.stats.gap - en2.stats.gap);
                break;
            case 'highest_positive_gap':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en2.stats.gap - en1.stats.gap);
                break;
            case 'highest_standard_deviation':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en1.stats.score - en2.stats.score);
                break;
            case 'lowest_standard_deviation':
                this.customers = this.customers.sort((en1: ContainerEntityGroupItemCustomer, en2: ContainerEntityGroupItemCustomer) => en1.stats.score - en2.stats.score);
                break;
        }
    }
}
class ContainerEntityGroupItemCustomer {
    id: number;
    name: string;
    iconPath: string;
    stats: ContainerDataModel
    revenue: string;
    industries: string;

    constructor(obj: any) {
        this.id = obj.business.id;
        this.name = obj.business.name;
        this.iconPath = obj.business.icon_path || Constants.BUSINESS_TMP_LOGO;
        this.revenue = obj.business.revenue;
        this.industries = obj.business.industries && obj.business.industries.length > 0 ? obj.business.industries.join(', ') : '';
        this.stats = new ContainerDataModel(obj)
    }
}
class ContainerEntityGroupItem {
    type: BusinessPageTypes | BankPageTypes;
    title: string;
    itemData: [
        {
            name: string,
            score: number,
            importance: number,
            gap: number,
            respondents: number,
            scores: number,
            customersContainer: ContainerEntityGroupItemCustomerContainer | null | undefined
        }
    ];

    constructor(obj: any) {
        this.type = obj.type;
        this.title = this.getTitle(obj.type);
        this.itemData = this.getItemData(obj.item_data);
    }

    getTitle(type: BusinessPageTypes | BankPageTypes): string {
        switch (type) {
            case BusinessPageTypes.BANK:
                return 'Banks';
            case BusinessPageTypes.COUNTRY:
            case BankPageTypes.COUNTRY:
                return 'Countries';
            case BusinessPageTypes.PRODUCT_AREA:
            case BankPageTypes.PRODUCT_AREA:
                return 'Product areas';
        }
    }

    getItemData(itemData: any) {
        if(!itemData) {
            return [];
        }

        return itemData.map(itd => new ContainerDataModel({
            ...itd,
            ...{redirect_url: this.getItemRedirectUrl(this.type, itd)}
        }));
    }


    getItemRedirectUrl(type: BusinessPageTypes | BankPageTypes, itemData): any {
        switch (type) {
            case BusinessPageTypes.BANK:
                return `/business/dashboard/banks/${itemData.id}`;
            case BusinessPageTypes.COUNTRY:
                return `/business/dashboard/countries/${itemData.name}`;
            case BusinessPageTypes.PRODUCT_AREA:
                return `/business/dashboard/product-areas/${itemData.name}`;
            case BankPageTypes.COUNTRY:
                return `/bank/countries/${itemData.name}`;
            case BankPageTypes.PRODUCT_AREA:
                return `/bank/product-areas/${itemData.name}`;
        }
    }
}
