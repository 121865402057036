<template>
    <div class="info-section">
        <div class="info-section__left">
            <div class="info-section__left--title">
                <inline-svg :src="titleIcon" width="19px" height="19px"/>
                <span>{{ title }}</span>
                <strong>{{ count }}</strong>
            </div>
            <div class="info-section__left--text">
                {{ description }}
            </div>
        </div>
        <div class="info-section__right">
            <button class="info-section__right--btn" @click="handleClickAdd">
                <span>{{ addButtonText }}</span>
            </button>
            <DashboardSortSelect
                @onSortingChange="handleSelectChange"
            />
        </div>
    </div>
</template>

<script>
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect";

export default {
    name: "DashboardCommonHeader",
    components: {
        DashboardSortSelect
    },
    props: {
        title: String,
        titleIcon: String,
        count: Number,
        description: String,
        addButtonText: String,
        defaultSortValue: {
            type: String,
            default: 'lowest_score'
        }
    },
    emits: ['onSortChange', 'onClickAdd'],
    methods: {
        handleSelectChange(e) {
            this.$emit('onSortChange', e);
        },
        handleClickAdd() {
            this.$emit('onClickAdd');
        }
    }
}
</script>

<style scoped lang="scss">
.info-section {
    padding-left: 8px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
        &--title {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            span {
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
                margin-left: 9px;
                margin-right: 9px;
            }

            strong {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        &--text {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            opacity: 0.5;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 36px;

        &--btn {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;

            span {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #435BF4;
                margin-left: 8px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        &__right {
            width: 100%;
            justify-content: space-between;
            margin-top: 10px;

            &--btn {
                padding-left: 0;

                span {
                    margin-left: 0;
                }
            }
        }
    }
}
</style>
