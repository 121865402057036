<template>
    <div class="stats-table">
        <div class="stats-table__header">
            <div class="stats-table__header--headings">
                <div class="headings-item">{{ $t('Score') }}</div>
                <div class="headings-item">{{ $t('Importance') }}</div>
                <div class="headings-item">{{ $t('Gap') }}</div>
                <div class="headings-item">{{ $t('Respondents') }}</div>
                <div class="headings-item">{{ $t('Scores') }}</div>
            </div>

            <div class="stats-table__header--score">
                <div class="score-title">
                    <h4>{{ scoreTitle }}</h4>
                    <p>{{ scoreText }}</p>
                </div>

                <DashboardStatsValues
                    :score="totalScore.score"
                    :importance="totalScore.importance"
                    :gap="totalScore.gap"
                    :respondents="totalScore.respondents"
                    :scores="totalScore.scores"
                />
            </div>
        </div>

        <div class="stats-table__body">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import DashboardStatsValues from "@/buying-teams/shared-components/dashboard/DashboardStatsValues";

export default {
    name: "DashboardStatsTable",
    components: {
        DashboardStatsValues
    },
    props: {
        scoreTitle: String,
        scoreText: String,
        totalScore: Object
    }
}
</script>

<style scoped lang="scss">
.stats-table {
    &__header {
        position: sticky;
        top: 122px;
        background: #F6F6F6;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        z-index: 2;

        &--headings {
            margin-right: 16px;
            display: flex;
            max-width: 450px;
            margin-left: auto;
            margin-bottom: 12px;

            .headings-item {
                width: 20%;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                opacity: 0.5;
                text-align: center;
            }
        }

        &--score {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .score-title {
                h4 {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 13px;
                    color: #000000;
                    opacity: 0.87;
                    margin-bottom: 8px;
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    opacity: 0.5;
                    margin-bottom: 0;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        &__header {
            &--headings {
                max-width: 360px;
            }

            &--score {
                .stats-values {
                    min-width: 360px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        &__header {
            top: 54px;
            padding-top: 10px;
        }
    }

    @media (max-width: 768px) {
        &__header {
            &--headings {
                display: none;
            }

            &--score {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .stats-values {
                    min-width: 300px;
                }
            }
        }
    }
}
</style>
