<template>
    <div class="stats-values">
        <div class="stats-values__item score-indicator" :class="getStatusClass(score)">
            <span class="stats-values__item--mobile-title">{{ $t('Score') }}</span>
            <span class="stats-values__item--indicator"></span>
            <div class="stats-values__item--value">{{ numberDecimalsFormat(score) }}</div>
        </div>
        <div class="stats-values__item importance-indicator">
            <span class="stats-values__item--mobile-title">{{ $t('Importance') }}</span>
            <span class="stats-values__item--indicator"></span>
            <div class="stats-values__item--value">{{ numberDecimalsFormat(importance) }}</div>
        </div>
        <div class="stats-values__item gap-indicator" :class="getGapStatusClass(gap)">
            <span class="stats-values__item--mobile-title">{{ $t('Gap') }}</span>
            <span class="stats-values__item--indicator"></span>
            <div class="stats-values__item--value">{{ numberDecimalsFormat(gap) }}</div>
        </div>
        <div class="stats-values__item">
            <span class="stats-values__item--mobile-title">{{ $t('Respondents') }}</span>
            <span class="stats-values__item--indicator"></span>
            <div class="stats-values__item--value">{{ respondents }}</div>
        </div>
        <div class="stats-values__item">
            <span class="stats-values__item--mobile-title">{{ $t('Scores') }}</span>
            <span class="stats-values__item--indicator"></span>
            <div class="stats-values__item--value">{{ scores }}</div>
        </div>
    </div>
</template>

<script>
import {getStatusClass, numberDecimalsFormat, getGapStatusClass} from "@/core/helpers/GlobalHelper";

export default {
    name: "DashboardStatsValues",
    props: {
        score: Number,
        importance: Number,
        gap: Number,
        respondents: Number,
        scores: Number,
    },
    data() {
        return {
            getStatusClass,
            numberDecimalsFormat,
            getGapStatusClass
        }
    }
}
</script>

<style scoped lang="scss">
.stats-values {
    min-width: 450px;
    display: flex;

    &__item {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--indicator {
            display: block;
            width: 8.3px;
            height: 8.3px;
            background: #ECECEC;
            border-radius: 50%;
            margin-right: 4px;
        }

        &--value {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #313131;
        }

        &--mobile-title {
            display: none;
        }

        &.score-indicator {
            &.status-danger {
                .stats-values__item--indicator {
                    background: #E22D21;
                }
            }

            &.status-warning {
                .stats-values__item--indicator {
                    background: #FBDC6C;
                }
            }

            &.status-success {
                .stats-values__item--indicator {
                    background: #2BC490;
                }
            }
        }

        &.importance-indicator {
            .stats-values__item--indicator {
                background: #CED4F8;
            }
        }

        &.gap-indicator {
            .stats-values__item--indicator {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 6px;
                }
            }
        }

        &.gap-indicator {
            &.status-success {
                .stats-values__item--indicator {
                    background-color: #DBF7ED;

                    &:after {
                        background-image: url("/media/buying/icons/gap-value-icon.svg");
                        filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                    }
                }
            }

            &.status-danger {
                .stats-values__item--indicator {
                    background-color: #FBDDDB;

                    &:after {
                        background-image: url("/media/buying/icons/gap-value-icon.svg");
                        filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        &__item {
            padding-top: 14px;
            position: relative;
            justify-content: flex-start;

            &--mobile-title {
                display: block;
                position: absolute;
                top: 0;
                font-size: 9px;
                color: #999999;
            }
        }
    }
}
</style>
